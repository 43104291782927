import React, { FunctionComponent } from "react";
import { useAppState, useEffects } from "../overmind";
import { Page } from "../types/Page";

import { pages, createUrl, PageAccess } from "./pages";

export const App: FunctionComponent = () => {
  const state = useAppState();

  const effects = useEffects();
  let CurrentPage = null;

  if (!state.isInitialLoadComplete) {
    return <div>Laster</div>;
  }
  if (!pages[state.currentPage]) {
    return <div>404</div>;
  }

  CurrentPage = pages[state.currentPage];

  if (!CurrentPage) {
    return <div>404</div>;
  }

  const CurrentPageComponent = CurrentPage.component;

  const isGuest = !state.user;
  if (isGuest && CurrentPage.restrictedTo === PageAccess.AUTH) {
    effects.toast("Du må logge inn for å se denne siden");
    effects.router.navigateTo(createUrl(Page.LOGIN));
  } else if (!isGuest && CurrentPage.restrictedTo === PageAccess.GUEST) {
    // effects.toast("Du må logge ut for å se denne siden");
    effects.router.navigateTo(createUrl(Page.HOME));
  }

  if (!isGuest) {
    return <CurrentPageComponent />;
  }
  return <CurrentPageComponent />;
};

export default App;
