/* eslint-disable import/no-duplicates */
import fileDownload from "js-file-download";
import { format, getWeek } from "date-fns";
import { nb } from "date-fns/locale";
import { Shipment, ShipmentProject } from "../types/Shipment";

const addFieldToCSVRow = (field?: string) => {
  return `${
    field !== undefined ? field.replaceAll(",", ".").replaceAll("\n", "") : ""
  };`;
};

const drawRow = (entry: Shipment, projects: ShipmentProject[]): string => {
  let result = "";
  const week = getWeek(entry.deliveryDay);
  console.log(entry.deliveryDay);
  projects.forEach((x) => {
    result += addFieldToCSVRow(week.toString());
    result += addFieldToCSVRow(
      `'${format(entry.deliveryDay, "dd.MM.yyyy", { locale: nb })}`
    );
    result += addFieldToCSVRow(entry.site);
    result += addFieldToCSVRow(`'${entry.shipmentId}`);
    result += addFieldToCSVRow(x.projID);
    result += addFieldToCSVRow(x.marked);
    result += addFieldToCSVRow(x.city);
    result += addFieldToCSVRow(x.address);
    result += addFieldToCSVRow(x.contactPerson);
    result += addFieldToCSVRow(x.contactPhone);
    result += addFieldToCSVRow(`'${x.volume}`);
    result += addFieldToCSVRow(`'${x.width}`);
    result += addFieldToCSVRow(`'${x.height}`);
    result += addFieldToCSVRow(`'${x.length}`);
    result += addFieldToCSVRow(x.transport);
    result += addFieldToCSVRow(x.lifting ? "Ja" : "Nei");
    result += addFieldToCSVRow(x.markedDrive);
    result += "\r\n";
  });
  return result;
};

const drawHeader = (): string => {
  const row: any[] = [
    "Uke",
    "Kjøredato",
    "Site",
    "Forsendelsesid (Turnr)",
    "Prosjektnr",
    "Merkes(Prosjektnavn)",
    "Postnr/sted",
    "Leveringsadresse",
    "Kontaktperson byggeplass",
    "Tlf Byggeplass",
    "Volum",
    "B",
    "H",
    "L",
    "Transport",
    "Kraning",
    "Merknad kjøring",
  ];

  return `${row.reduce(
    (sum: string, field: string) =>
      `${sum};${field ? field.toString().trim() : ""}`
  )}\r\n`;
};

export const downloadAsCSV = (
  entries: Shipment[],
  projects: ShipmentProject[]
) => {
  if (entries.length === 0) return;
  const markup =
    drawHeader() +
    entries
      .map((x) =>
        drawRow(
          x,
          projects.filter((proj) => x.shipmentId === proj.shipmentId)
        )
      )
      .reduce((all, row) => all + row);
  const date = new Date();
  const filename = `Kjøreliste_${format(date, "dd.MM.yyyy")}.csv`;
  fileDownload(markup, filename, undefined, "\uFEFF");
};
